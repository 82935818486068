<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row>
        <v-col
          cols="12"
          class="mt-3 px-6 d-flex"
          :class="{'justify-space-between': $vuetify.breakpoint.smAndUp, 'flex-column':$vuetify.breakpoint.xsOnly}"
        >
          <div class="d-flex flex-wrap align-center">
            <search-expand 
              v-model="searchVis" 
              placeholder="Buscar Visita"
            />
          </div>
          <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center">  
            <v-btn
              color="primary"
              class="mx-2"
              text
              small
              @click="leyenda = !leyenda"
            >   
              <v-fade-transition>            
                <v-icon 
                  size="22" 
                  left 
                  v-text="leyenda ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                />    
              </v-fade-transition>         
              Leyenda         
            </v-btn> 
          </div>
        </v-col>
        <leyenda v-model="leyenda" />
      </v-row>
      <v-row v-if="!loadHistVisita">
        <v-col cols="12">
        <v-data-iterator
          :search="searchVis"
          :items="items"
          :items-per-page="10"
          :footer-props="{itemsPerPageText: 'Mostrar'}"
          :sort-by="sortBy"
          :sort-desc="orderSort"
          :class="{'light-blue-color': $vuetify.breakpoint.xsOnly}"
        >
          <template v-slot:header>
            <v-list-item class="grey lighten-5">
              <v-list-item-content>
                <v-row>
                  <template v-if="$vuetify.breakpoint.smAndUp">
                    <v-col cols="2" class="d-flex align-center justify-center ">
                      <span 
                        class="blue-grey--text font-weight-regular text--lighten-2"
                      >
                        H.Inicio
                      </span>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <span 
                        class="blue-grey--text font-weight-regular text--lighten-2"
                      >
                        H.Final
                      </span>                      
                    </v-col>
                    <v-col cols="2" class="d-flex justify-center align-center">
                      <span class="blue-grey--text text--lighten-2 font-weight-regular">Duración</span>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center align-center">
                      <a href="" class="text-decoration-none" @click.prevent="activeSort('cod_visita_tipo')">
                        <span 
                          class="blue-grey--text font-weight-regular text--lighten-2"
                          :class="{'font-weight-bold': columnActived('cod_visita_tipo') }"
                        >
                          Tipo
                        </span>
                        <template v-if="columnActived('cod_visita_tipo')">
                          <v-fade-transition>
                            <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                          </v-fade-transition>
                        </template>
                        <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                      </a>
                    </v-col>
                    <v-col cols="3" class="d-flex justify-center align-center">
                      <a href="" class="text-decoration-none" @click.prevent="activeSort('fecha')">
                        <span 
                          class="blue-grey--text font-weight-regular text--lighten-2"
                          :class="{'font-weight-bold': columnActived('fecha') }"
                        >
                          Fecha
                        </span>
                        <template v-if="columnActived('fecha')">
                          <v-fade-transition>
                            <v-icon size="18" class="ml-1" color="blue-grey" v-text="orderSort ? 'mdi-sort-variant' : 'mdi-sort-reverse-variant'"/>
                          </v-fade-transition>
                        </template>
                        <v-icon v-else size="18" color="blue-grey lighten-3">mdi-menu-swap</v-icon>
                      </a>
                    </v-col>
                  </template>
                  <v-col v-else cols="12" class="d-flex align-center justify-space-between">
                    <span 
                      class="blue-grey--text font-weight-regular text--lighten-2"
                    >
                      Listado
                    </span>
                    <v-btn
                      color="primary"
                      class="mx-2"
                      text
                      small
                      @click="leyenda = !leyenda"
                    >   
                      <v-fade-transition>            
                        <v-icon 
                          size="22" 
                          left 
                          v-text="leyenda ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                        />    
                      </v-fade-transition>         
                      Leyenda         
                    </v-btn> 
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center justify-center px-6">
                <span 
                  class="blue-grey--text font-weight-regular text--lighten-2"
                >
                  Indicadores
                </span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :class="{'mb-3': $vuetify.breakpoint.xsOnly}" />
          </template>
          <template v-slot:default="{ items }">
            <v-list 
              class="pt-0"
              :class="{'px-3 transparent': $vuetify.breakpoint.xsOnly}"
            >
              <template v-for="(visita,i) in items"> 
                <v-slide-y-transition
                  :key="'tp-'+ i"
                  group
                  class="py-0"
                >
                  <historia-visita :data_visita="visita" :key="'historyV-'+i" @click="activeDetails(visita)" />
                </v-slide-y-transition>                
                <v-divider class="hidden-xs-only" v-if="i < items.length - 1" :key="'section-'+i" />
              </template>
            </v-list>
          </template>
          <template v-slot:no-data>
            <div class="text-center pa-4">
              <span class="blue-grey--text font-italic">No se encontraron Registros disponibles</span>
            </div>
          </template>
        </v-data-iterator>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-skeleton-loader        
            type="table-tbody"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Visitas',
  components: {
    Leyenda: () => import(
      /* webpackChunkName: "leyenda" */
      '@/views/Vendedores/Components/HistoricoVisitas/Leyenda.vue'
    ),
     HistoriaVisita: () => import(
      /* webpackChunkName: "historia-visita" */
      './ItemVisita.vue'
    ),
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    searchVis: '',
    selected: '',
    view_details: false,
    item_details: {},
    lista: [],
    loadHistVisita: false,
    fecha_visita_ini: '',
    fecha_visita_fin: '',
    sortBy: '',
    orderSort: true,
    view_btn_list: [],
    filtrar:{
      imagen: '',
      pedido_visita: '',
      geo: '',
      motivo_id: [],
      cod_visita_tipo: [],
    },
    leyenda: false,
  }),
  methods: {
    activeDetails(item) {
      this.$emit('show', item);
    },
    cerrarDetails(value) {
       this.view_details = value;
       this.selected= null;
    },
    updateHist() {
      this.$emit('update');
    },
    solicitarHistoria(fechas) {
      this.$emit('updateHistoria', fechas);
      this.fecha_visita_ini = fechas.ini;
      this.fecha_visita_fin = fechas.fin;
      this.getHistVisitas();
    },
    calcularDuracion(item) {
      let inicio = moment(item.fecha+' '+item.hora_inicio, "HH:mm:ss");
      let fin = moment(item.fecha+' '+item.hora_fin, "HH:mm:ss");

      return fin.diff(inicio, 'minutes') + ' min';
    },
    activeSort(column) {
      this.sortBy = column;
      this.orderSort = !this.orderSort;
    },
    columnActived(column) {
      return column === this.sortBy
    },
  },

}
</script>